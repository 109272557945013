import React from 'react'
import Helmet from 'react-helmet'

import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { Hero } from '../components/Hero'
import config from '../utils/config'

const images = [
  { url: '/talk_thumbnails/multinodes_ismb21.png', title: 'ISMB 2021', link:"" },
  { url: '/talk_thumbnails/normalizing_flows_2020.png', title: 'Internal talk 2020', link:"" },
  { url: '/talk_thumbnails/20y_bit_2022.png', title: 'University event 2022', link:"https://youtu.be/cOgytr8GmJQ" },
]

export default function Talks() {
  const title = 'Talks'

  return (
    <div>
      <Helmet title={`${title} | ${config.siteTitle}`} />
      <SEO />
      <div className="container">
        <Hero title={title} />
      </div>

      <section className="segment">
        <div className="container">
          <div className="image-preview">
            {images.map((image) => {
              return (
                <div className="card" key={image.url}>
                  <a href={image.link} target="_blank" rel="noreferrer">
                    <h2>{image.title}</h2>

                    <div
                      className="image-thumbnail"
                      style={{ backgroundImage: `url('${image.url}')` }}
                      alt={image.title}
                    />
                  </a>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </div>
  )
}

Talks.Layout = Layout
